import { UppercaseFirstLetter } from './text.types';

// ADMIN
export const ADMIN_ROLE: UppercaseFirstLetter = 'Admin';

// CHAT
export const CHAT_ROLE: UppercaseFirstLetter = 'AccessInsights';

// AIM
export const AIM_ROLE: UppercaseFirstLetter = 'Aim';
export const AIM_ADMIN_ROLE: UppercaseFirstLetter = 'AdminAim';

// FUN
export const FUN_ROLE: UppercaseFirstLetter = 'Fun';
export const FUN_ADMIN_ROLE: UppercaseFirstLetter = 'AdminFun';
export const FUN_STAKEHOLDER_ROLE: UppercaseFirstLetter = 'FunStakeholder';
export const FUN_ACCOUNT_DIRECTOR_ROLE: UppercaseFirstLetter = 'FunAccountDirector';
